<template>
  <div>
    <client-add></client-add>
  </div>
</template>

<script>
import ClientAdd from './ClientAdd.vue';

export default {
  components: {
    ClientAdd,
  },

  data() {
    return {};
  },
};
</script>
